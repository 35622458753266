import user from "@/services/user";
import axios from "axios";
import api from "@/services/request";
import {notify} from "@kyvg/vue3-notification";
import store from "../store";
import router from "../router/index";
import auth from "./auth";

const utils = {
  /**
   * Iterate through the component sub-objects to execute the replaces
   * @param subcomponent
   * @returns {*}
   */
  parseSubComponent(subcomponent, modal = false) {
    if (typeof subcomponent == "object") {
      Object.keys(subcomponent).forEach(function (subkey) {
        let val = subcomponent[subkey];
        if (typeof val == "string") {
          subcomponent[subkey] = utils.parseSubComponent(val, modal);
        }
        if (typeof val == "object") {
          if (subcomponent.type == "Form" && modal) {
            subcomponent[subkey].isModal = true;
          }
          subcomponent[subkey] = utils.parseSubComponent(val, modal);
        }
      });
    }
    return subcomponent;
  },

  /**
   * Parse and update component data with stored values
   * @param component
   * @returns {Promise<any>}
   */
  async parseComponentData(component, modal) {
    var def = JSON.parse(JSON.stringify(component));
    Object.keys(def).forEach(function (key) {
      let val = def[key];
      if (typeof val == "string") {
        def[key] = utils.replaceComponentData(val);
      }
      if (typeof val == "object") {
        def[key] = utils.parseSubComponent(val, modal);
      }
    });
    return def;
  },

  /**
   * Replace a given {{variable}} with its value from vue store
   * @param val
   * @returns {*}
   */
  replaceComponentData(val) {
    let regex = /{{\w.+}}/g;
    let matches = regex.exec(val);
    //TODO se precisar um dia, implementar multiplos replaces
    if (matches != null) {
      let variable = matches[0].replace(/{{|}}/g, "");
      let variableValue = utils[variable];
      let replaced = val.replace(regex, variableValue);
      return replaced;
    }
    return val;
  },

  /**
   * Returns the Name of the user in session
   * @returns {string}
   */
  getUserName() {
    let UserSession = user.getUserSession();
    return UserSession.user.name;
  },

  /**
   * Returns the user object in session
   * @returns {string}
   */
  getUserId() {
    let UserSession = user.getUserSession();
    return UserSession.user.id;
  },

  /**
   * Return the Slug version of a given string
   * @param value
   * @param input
   * @returns {{input, filteredValue: string}}
   */
  toSlug(value, input) {
    let result = value.toLowerCase().replace(/ /g, "-").replace(/[^\w-]+/g, "");
    return {filteredValue: result, input: input};
  },

  /**
   * Returns the trimmed version of a given string.
   * @param value
   * @param input
   * @returns {{input, filteredValue: *}}
   */
  trim(value, input) {
    return {filteredValue: value.trim(), input: input};
  },

  /**
   * Returns the string without any special characters and spaces.
   * @param value
   * @param input
   * @returns {{input, filteredValue: *}}
   */
  noSpecialChars(value, input) {
    return {filteredValue: value.replace(/[^\w]/g, ""), input: input};
  },

  /**
   * Returns the upper case version of a given string.
   * @param value
   * @param input
   * @returns {{input: *, filteredValue: string}}
   */
  upperCase(value, input) {
    return {filteredValue: value.toUpperCase(), input: input};
  },

  /**
   * Returns the lower case version of a given string.
   * @param value
   * @param input
   * @returns {{input, filteredValue: string}}
   */
  lowerCase(value, input) {
    return {filteredValue: value.toLowerCase(), input: input};
  },

  /**
   * Return the Slug version of a given string
   * @param value
   * @param input
   * @returns {{input, filteredValue: *}}
   */
  capitalizes(value, input) {
    input.error = false;
    let result = "";
    if (value.includes(" ")) {
      let words = value.split(" ");
      for (let i = 0; i < words.length; i++) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].substring(1);
      }
      result = words.join(" ");
    } else {
      result = value.charAt(0).toUpperCase() + value.substring(1);
    }
    return {filteredValue: result, input: input};
  },

  /**
   * Returns the string also the error message if validation fails.
   * @param value
   * @param input
   * @returns {{input, filteredValue}}
   */
  isRequired(value, input, validation) {
    let error = 'O campo "' + input.label + '" é obrigatório.';

    if (!value || value === "" || value === undefined || value === null || value.length == 0) {
      input.invalid = true;
      input.error = input.error && input.error !== "" ? input.error : error;
      validation = false;
    } else if (input.error && input.error == error) {
      delete input.error;
      delete input.invalid;
    }
    return validation;
  },

  commonNameValidation(name) {
    if (name) {
      return /^[A-Za-z]+/.test(name);
    }
    return false;
  },

  commonPhoneValidation(phone) {
    if (phone) {
      return /^(\d{2})\D*(\d{5}|\d{4})\D*(\d{4})$/.test(phone);
    }
    return false;
  },

  commonEmailValidation(email) {
    if (email) {
      return /^(?!$)(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zAZ]{2,}))$/.test(
        email
      );
    }
    return false;
  },

  /**
   * Validates url to the triggerEventField in InputComponent.vue;
   * @param {String} value Value of the input, the email itself.
   * @param {*} input Input object with all its parameters.
   * @returns
   */
  validateUrl(value, input) {
    let result = {filteredValue: value, error: false};
    if (value === null || value === "") {
      return result;
    }

    result.error = "URL inválida, por favor insira uma url válida (ex: https://www.example.com).";
    let regex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;

    if (regex.test(value)) {
      result.error = false;
    }
    return result;
  },

  /**
   * Validates the e-mail to the triggerFieldEvent in InputComponent.vue
   * @param {String} value Value of the input, the email itself.
   * @param {*} input Input object with all its parameters.
   * @returns
   */
  validateEmail(value, input) {
    let result = {filteredValue: value, error: false};
    if (value === null || value === "") {
      return result;
    }

    result.error = "E-mail inválido, por favor, digite um e-mail válido (ex: example@example.com).";
    let regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (regex.test(value)) {
      result.error = false;
    }
    return result;
  },

  /**
   * Validates the phone for Brazil in InputComponent.vue
   * @param {String} value Value of the input, the email itself.
   * @param {*} input Input object with all its parameters.
   * @returns
   */
  validatePhone(value, input) {
    if (value == undefined) {
      return;
    }
    let result = {filteredValue: value, error: false};
    if (value === null || value === "") {
      return result;
    }
    // remove any extra ponctuation and hiphen, this fixes when you copy/paste a formatted value
    value = value.replace(/[^\d]+/g, "");

    result.error =
      "Número de telefone inválido, por favor, digite um número válido (ex: (00) 0000-0000 ).";
    const regex = /^(\d{10}|\(\d{2}\)[-.\s]?\d{8}|\d{2}[-.\s]?\d{8})$/;

    if (regex.test(value)) {
      result.error = false;
    }
    return result;
  },

  /**
   * Validates the cell phone for Brazil in InputComponent.vue
   * @param {String} value Value of the input, the email itself.
   * @param {*} input Input object with all its parameters.
   * @returns
   */
  validateCellPhone(value, input) {
    if (value == undefined) {
      return;
    }
    let result = {filteredValue: value, error: false};
    if (value === null || value === "") {
      return result;
    }

    // remove any extra ponctuation and hiphen, this fixes when you copy/paste a formatted value
    value = value.replace(/[^\d]+/g, "");

    result.error =
      "Número de telefone inválido, por favor, digite um número válido (ex: (00) 00000-0000).";
    const regex = /^(\d{11}|\(\d{2}\)[-.\s]?\d{9}|\d{2}[-.\s]?\d{9})$/;

    if (regex.test(value)) {
      result.error = false;
    }
    return result;
  },

  /**
   * Returns the string or error if validation fails.
   * @param value
   * @param input
   * @returns {{input, filteredValue}}
   */
  validateCNPJ(value, input) {
    let result = {filteredValue: value, error: false};
    if (value === null || value === "") {
      return result;
    }

    // remove any extra ponctuation and hiphen, this fixes when you copy/paste a formatted value
    value = value.replace(/[^\d]+/g, "");

    result.error = "O número do documento inserido é inválido.";

    let cnpj = value.split("");
    let repeatedTrial = new Set(cnpj).size;
    if (repeatedTrial === 1) {
      return result;
    }

    if (cnpj != null && cnpj.length == 14) {
      let trial = 5;
      let sum = 0;
      for (let i = 0; i < 12; i++) {
        sum += cnpj[i] * trial;
        trial = trial === 2 ? 9 : trial - 1;
      }
      let rest = sum % 11;
      let firstDigit = rest < 2 ? 0 : 11 - rest;
      if (cnpj[12] != firstDigit) {
        return result;
      }

      trial = 6;
      sum = 0;
      for (let i = 0; i < 13; i++) {
        sum += cnpj[i] * trial;
        trial = trial === 2 ? 9 : trial - 1;
      }
      let secondDigit = sum % 11 < 2 ? 0 : 11 - (sum % 11);
      if (cnpj[13] == secondDigit) {
        result.error = false;
        return result;
      }
    }
    return result;
  },

  /**
   * Validates CPF and generates error message in case validation fails.
   * @param value
   * @param input
   * @returns {{input, filteredValue}}
   */
  validateCPF(value, input) {
    let result = {filteredValue: value, error: false};
    if (value === null || value === "") {
      return result;
    }

    // remove any extra ponctuation and hiphen, this fixes issue when you copy/paste a formatted value
    value = value.replace(/[^\d]+/g, "");

    result.error = "O número do documento inserido é inválido.";

    let cpf = value.split("");
    let repeatedTrial = new Set(cpf).size;
    if (repeatedTrial === 1) {
      return result;
    }

    if (cpf.length == 11) {
      let trial = 10,
        sum = 0;
      for (let i = 0; i < 9; i++) {
        sum += cpf[i] * trial;
        trial = trial - 1;
      }
      let rest = (sum * 10) % 11;
      rest = rest === 10 ? 0 : rest;
      if (cpf[9] != rest) {
        return result;
      }

      trial = 11;
      sum = 0;
      for (let i = 0; i < 10; i++) {
        sum += cpf[i] * trial;
        trial = trial - 1;
      }
      rest = (sum * 10) % 11;
      rest = rest == 10 ? 0 : rest;
      if (cpf[10] == rest) {
        result.error = false;
        return result;
      }
    }
    return result;
  },

  biggerThan(value, input, param) {
    let result = {filteredValue: value, error: false};
    value = value.replace(" ", "");
    param = param.replace(" ", "");
    if (Number(value) <= Number(param)) {
      result.error = `Digite um valor superior a ${Number(param).toFixed(2)}.`;
    }
    return result;
  },

  /**
   * Validates if the given date is before a specific one.
   * @param value
   * @param input
   * @param param
   * @returns {{input, filteredValue}|{input, filteredValue: string}}
   */
  beforeDate(value, input, param) {
    let result = {filteredValue: value, error: false};
    let date = new Date(value).toLocaleDateString();
    let limitDate = new Date(param).toLocaleDateString();
    let day = new Date(param).getDate() - 1;
    let compareDate = new Date(param).setDate(day);
    compareDate = new Date(compareDate).toLocaleDateString();
    if (date > compareDate) {
      result.error = 'O campo "' + input.label + '" exige uma data anterior à ' + limitDate + ".";
      return result;
    }
    return result;
  },

  /**
   * Validates if the given date is after a specific one.
   * @param value
   * @param input
   * @param param
   * @returns {{input, filteredValue}|{input, filteredValue: string}}
   */
  afterDate(value, input, param) {
    let result = {filteredValue: value, error: false};
    let date = new Date(value).toLocaleDateString();
    let limitDate = new Date(param).toLocaleDateString();
    let day = new Date(param).getDate() + 1;
    let compareDate = new Date(param).setDate(day);
    compareDate = new Date(compareDate).toLocaleDateString();
    if (date < compareDate) {
      result.error = 'O campo "' + input.label + '" exige uma data após ' + limitDate + ".";
      return result;
    }
    return result;
  },

  setInputsFromJsonFile(value) {
    let inputs;
    value.definitions.components.forEach((v) => {
      if (v.type === "Form") {
        inputs = v.params.inputs;
      }
    });
    inputs.forEach((v) => {
      v["included"] = true;
      v["showDatatable"] = false;
    });
    return inputs;
  },

  searchCep(cep) {
    return axios.get("https://brasilapi.com.br/api/cep/v2/" + cep);
  },

  searchCNPJ(cnpj) {
    return axios.get("https://brasilapi.com.br/api/cnpj/v1/" + cnpj);
  },

  executeIntegrationCall(integration, data) {
    // return axios.get('https://brasilapi.com.br/api/cep/v2/'+cep);
  },

  /**
   * Replaces the content of a string with given parameters.
   * @param haystack
   * @param needles
   * @param replacement
   * @returns {string|*}
   */
  replaceStrings(haystack, needles, replacement) {
    let result = haystack;
    if (
      haystack &&
      haystack !== "" &&
      needles &&
      needles != [] &&
      replacement &&
      replacement != [] &&
      needles.length === replacement.length
    ) {
      needles.forEach((needle, i) => {
        result = result.replace("{" + needle + "}", replacement[i]);
      });
    }
    return result;
  },

  setFullAddress(data) {
    let result = data;
    if (data.length != undefined) {
      data.forEach((item, ind) => {
        result[ind]["completeData"] =
          item.street +
          ", " +
          item.address_number +
          " - " +
          item.neighborhood +
          ", " +
          item.city.name;
      });
    } else {
      result["completeData"] =
        data.street +
        ", " +
        data.address_number +
        " - " +
        data.neighborhood +
        ", " +
        data.city.name;
      result = [result];
    }
    return result;
  },

  sum(dependent, variable, formData = null) {
    return Number(formData[dependent]) + Number(formData[variable]);
  },

  calcItemTotal(accumulator, variableName, data) {
    if (data.quantity != undefined && data.unit_value != undefined) {
      const unit_value = data.unit_value.replace(/^R\$\s/, "");
      const discountPercentage = data.discount_percentage === undefined ? 0 : Number(data.discount_percentage);
      const shippingCost = data.shipping_cost = data.shipping_cost == undefined ? 0 : data.shipping_cost;
      return (
        Number(data.quantity) * unit_value * (1 - discountPercentage / 100) +
        Number(shippingCost)
      ).toFixed(2);
    }
  },

  setItemTotalDiscount(accumulator, variableName, data) {
    if (data.quantity != undefined && data.unit_value != undefined) {
      const unit_value = data.unit_value.replace(/^R\$\s/, "");
      return (Number(data.quantity) * unit_value * (data[variableName] / 100)).toFixed(2);
    }
  },

  setItemUnitValue(accumulator, variableName, data) {
    if (data.product_id) {
      return data.product_id.selling_price;
    } else {
      return "0.00";
    }
  },

  setItemMeasurementUnit(accumulator, variableName, data) {
    if (data.product_id) {
      return data.product_id.measure_unit;
    } else {
      return data[accumulator];
    }
  },

  setItemMaxDiscount(accumulator, variableName, formData) {
    if (formData['discount_percentage'] > formData.product_id.max_discount) {
      return formData.product_id.max_discount;
    }
    return formData['discount_percentage'];
  },

  calcOrderTotal(accumulator, variableName, data) {
    if (
      variableName &&
      data[variableName] !== undefined &&
      data[variableName] !== "" &&
      data[variableName].length > 0
    ) {
      let result = data[variableName].reduce((accum, item) => {
        const totalItemValue = item.total_value ?? '0';
        const formattedValue = totalItemValue.replaceAll(',', '');
        return accum += Number(formattedValue);
      }, 0);
      if (data[accumulator] != result) {
        return result.toFixed(2);
      } else {
        return data[accumulator];
      }
    } else {
      return data[accumulator];
    }
  },

  calcOrderTotalDiscount(accumulator, variableName, data) {
    if (data[variableName]) {
      let result = data[variableName].reduce((accum, currentValue) => {
        return (accum += Number(currentValue.discount_value));
      }, 0);
      return result.toFixed(2);
    } else {
      return data[accumulator];
    }
  },

  operation(value1, operator, value2) {
    switch (operator) {
      case "!=":
        return value1 !== value2;
      case "==":
        return value1 === value2;
      case ">":
        return value1 > value2;
      case "<":
        return value1 < value2;
      case ">=":
        return value1 >= value2;
      case "<=":
        return value1 <= value2;
      case "&&":
        return value1 && value2;
      case "||":
        return value1 || value2;
      case "includes":
        return !!value1.includes(value2);
      default:
        return false;
    }
  },

  getCompostIndex(object, index) {
    let indexes = index.split(".");
    let result = object;
    indexes.forEach((index) => {
      if (result && typeof result === 'object' && index in result) {
        result = result[index];
      } else {
        result = null;
      }
    });
    return result;
  },

  async getInputOptions(option_id, refresh = null, searchString = '') {
    try {

      // TODO implement force refresh every X hours, store a new date in localStorage and compare with current date, if bigger than X hours, force refresh.

      if (refresh) {
        localStorage.removeItem(option_id);
      }

      const scope = location.pathname.includes("/page/") || location.pathname == "/" || location.pathname == "/login" ? "/public" : "/private";
      const defaultUrl = `${scope}/select-options?option_id=${option_id}${searchString}`;

      // Verify if there is a search in url and doesn't save if true.
      // TODO implement search in cached select options
      const hasSearch = /[?&]search=([^&]+)/.test(defaultUrl);
      if (!hasSearch) {
        const storedOptions = JSON.parse(localStorage.getItem(option_id));
        if (storedOptions) {
          return storedOptions.options;
        }
      }

      let response = null;
      if (defaultUrl.includes('public')) {
        response = await api.sendUnsafeRequest("get", defaultUrl, null);
      } else {
        response = await api.sendRequest("get", defaultUrl, null);
      }

      if (!hasSearch) {
        localStorage.setItem(option_id, JSON.stringify({url: defaultUrl, options: response.data}));
      }
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async getOptions(optionName, search = "") {
    let defaultUrl = "/private/select-options?option_id=" + optionName + "&search=" + search;
    const request = api.sendRequest("get", defaultUrl, null);
    await request.then((success) => {
      return success.data;
    });
  },

  async getSettings(settingsName) {
    let userSession = user.getUserSession();
    if (
      userSession.user.settings !== undefined &&
      userSession.user.settings[settingsName] !== undefined
    ) {
      return userSession.settings[settingsName];
    }
    let url = "/private/user-settings/" + settingsName;
    const request = api.sendRequest("get", url, null);
    return await request.then((success) => {
      if (success.status == 200) {
        if (userSession.user.settings === undefined) {
          userSession.user.settings = {};
          userSession.user.settings[settingsName] = {};
        }
        userSession.user.settings[settingsName] = success.data;
        window.localStorage.setItem("user", JSON.stringify(userSession));
      }
      return success.data;
    });
  },

  /**
   * Returns the profit margin of the product.
   */
  setProfitMargin(inputName, variableName, formData) {
    let result = "%";
    if (formData["selling_price"] && formData["cost_price"]) {
      let discount = formData["max_discount"] ? formData["max_discount"] : 0;
      let productPrice = formData["selling_price"] * (1 - discount / 100);
      let mathResult = ((productPrice - formData["cost_price"]) / productPrice) * 100;
      if (mathResult > 0 && !isNaN(mathResult)) {
        result = mathResult.toFixed(2) + "%";
      }
    }
    return result;
  },

  // Calculates the value per hour of a given value and hours of formData
  valuePerHour(inputName, variableName, formData) {
    let result = 0;
    if (formData && formData["hours"] && formData["value"]) {
      result = Number(formData["value"]) / Number(formData["hours"]);
    }
    return result.toFixed(2);
  },

  /**
   * Request user's location.
   */
  requestLocation() {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const {latitude, longitude} = position.coords;
            const location = {lat: latitude, lng: longitude};
            store.commit("setLocation", location);
            resolve(location);
          },
          (error) => {
            if (error.code == 1) {
              notify({
                type: "error",
                text: "Acesso à localização não permitido. Por favor, habilite.",
              });
            } else {
              console.error(error);
            }
            reject(false);
          }
        );
      } else {
        reject(false);
      }
    });
  },

  switchTheme() {
    let theme = this.getCurrentTheme();
    theme = theme == "dark" ? "light" : "dark";
    localStorage.setItem("theme", `${theme}`);
    store.commit("setTheme", theme);
    this.loadTheme(theme);
  },

  getCurrentTheme() {
    let theme = window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
    localStorage.getItem("theme") ? (theme = localStorage.getItem("theme")) : null;

    // Grants the login page will always be in light mode.
    let route = router.currentRoute.value.path;
    if (route == "/login") {
      theme = "light";
    }

    return theme;
  },

  async loadTheme(theme) {
    let tenant = await auth.getTenantData();
    const root = document.querySelector(":root");
    root.setAttribute("color-scheme", `${theme}`);
    const currentBgColor = getComputedStyle(root).getPropertyValue("--tenant-bg-color-light");

    if (tenant && tenant.bg_color != currentBgColor) {
      root.style.setProperty("--tenant-bg-color-light", `${tenant.bg_color}`);
      root.style.setProperty("--tenant-text-color-light", `${tenant.txt_color}`);
    }
  },

  /**
   * Checks if inputs have any validation failure.
   * @returns {boolean}
   */
  validateInputs(inputs, formData) {
    let validation = true;
    inputs.forEach((input) => {
      if (input.required === true) {
        let value = formData[input.name];
        if (value != undefined) {
          value = JSON.parse(JSON.stringify(value));
          validation = this.isRequired(value, input, validation);
        }
      }
    });

    // Returns false if any of inputs is invalid.
    validation = !inputs.some((input) => input.invalid);

    return validation;
  },


  /**
   * Sends the proposal and downloads it on response. Also opens whatsapp chat to send the proposal PDF file.
   * @param params
   * @param data
   */
  sendProposal(params, data) {
    const route = params.route;

    const request = api.sendRequest("post", route, data);
    request.then((success) => {
      const baseUrl = api.getBaseUrl();
      if (success.data.whatsapp) {
        // window.open(`${baseUrl}${success.data.whatsapp.openFile}`, "_blank");
        window.open(success.data.whatsapp.downloadUrl, "_blank");
        window.open(success.data.whatsapp.openChat, "_blank");
      }

      if (success.data.openFile) {
        window.open(`${baseUrl}${success.data.openFile}`, "_blank");
      }

      notify({
        type: "success",
        text: "Proposta enviada com sucesso!",
      });
    }).catch((error) => {
      notify({
        type: "error",
        text: error.message,
      });
    });
  },


  addQueryParamsToUrl(url, params) {
    let queryParams = "?";
    if (url.includes("?")) {
      queryParams = "&";
    }

    Object.keys(params).forEach((key) => {
      queryParams += `${key}=${params[key]}&`;
    });

    queryParams = queryParams.slice(0, -1);

    return `${url}${queryParams}`;
  },


  /**
   * Format the date using javascript toLocaleString().
   * @param value
   * @returns {string}
   */
  formatDateLocaleString(value) {
    if (value) {
      return new Date(value).toLocaleString();
    }
  },


  /**
   * Uses the download API to download files with a given file containing its hash as a property.
   * @param file
   * @returns {Promise<void>}
   */
  async downloadFile(file) {
    let appUrl = await api.getBaseUrl();
    let route = `${appUrl}/download?hash=${file.hash}`
    let link = document.createElement('a');
    link.style.display = 'none';
    link.setAttribute('download', file.filename);
    link.href = route;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    link.remove();
  },


  /**
   * Fetches the file path to load it on screen.
   * @param filepath
   * @returns {string}
   */
  fetchFilePath(filepath) {
    return api.getBaseUrl() + "/files/" + filepath;
  },

  createFilterStructure(filterParams, data, blacklist) {
    let object = data[0];
    let fields = Object.keys(data[0]);
    fields.forEach(field => {
      if (!(blacklist && blacklist.includes(field))) {

        let filter = {
          field: field,
          operator: "",
          value: ["", ""],
        }

        if (object[field]?.select_option_id) {
          filter.operator = '=';
          filter.value = null;
          filter.select_option_id = object[field]?.select_option_id;
        }

        if (Array.isArray(object[field]) && object[field].some(item => item.select_option_id)) {
          filter.operator = '=';
          filter.value = null;
          filter.select_option_id = object[field][0].select_option_id;
        }

        filterParams.push(filter);
      }
    });
  },

  /**
   * Calculates the percentage of a cost or selling price. Can be used for products taxes and discounts
   * @param inputName
   * @param variableName
   * @param data
   * @returns {string}
   */
  calcSellingPercentage(inputName, variableName, data) {
    const percentage = data['administration_charge'] ? data['administration_charge'] / 100 : 0;
    const total = Number(data['selling_price']) == 0 ? Number(data['rent_price']) : Number(data['selling_price']);
    return Number(percentage * total).toFixed(2);
  },


  /**
   * Calculates the property selling price including fees, charges and discounts.
   * @param inputName
   * @param variableName
   * @param data
   * @returns {string|string|*}
   */
  calcPropertySellingPrice(inputName, variableName, data) {
    const isSale = data.business_model.some(models => models.value == 'sale');

    if (!isSale) {
      return data[inputName];
    }

    let admChargePercentage = data['administration_charge'] ? data['administration_charge'] / 100 : 0;
    let propertyPrice = data['selling_price'] ? Number(data['selling_price']) : 0;
    let fees = data['other_fee'] ? Number(data['other_fee']) : 0;

    const maxDiscount = data['max_discount'] ? data['max_discount'] / 100 : 0;
    const discount = propertyPrice * maxDiscount;

    propertyPrice = (propertyPrice - discount).toFixed(2);
    const chargeVal = propertyPrice * admChargePercentage;

    return (Number(propertyPrice) + chargeVal + fees).toFixed(2);
  },

  /**
   * Calculates the property rent price including fees, taxes, charges and discounts.
   * @param inputName
   * @param variableName
   * @param data
   * @returns {string}
   */
  calcPropertyRentPrice(inputName, variableName, data) {
    const isRent = data.business_model.some(models => models.value == 'rent');

    if (!isRent) {
      return data[inputName];
    }

    let admChargePercentage = data['administration_charge'] ? Number(data['administration_charge']) / 100 : 0;
    let rentPrice = data['rent_price'] ? Number(data['rent_price']) : 0;
    let otherFee = data['other_fee'] ? Number(data['other_fee']) : 0;
    let isHoaIncluded = data['product_details.hoa_tax_included'] && data['product_details.hoa_tax_included'] == '1' ? true : false;
    let propertyTax = data['product_details.property_tax'] ? Number(data['product_details.property_tax']) : 0;
    let rentalFee = data['rental_fee'] ? Number(data['rental_fee']) : 0;

    let hoaTax = 0;
    if (!isHoaIncluded) {
      hoaTax = data['product_details.hoa_tax'] ? Number(data['product_details.hoa_tax']) : 0;
    }

    const maxDiscount = data['max_discount'] ? data['max_discount'] / 100 : 0;
    const discount = rentPrice * maxDiscount;

    let admCharge = rentPrice * admChargePercentage;

    return Number(rentPrice + admCharge + rentalFee + otherFee + hoaTax + propertyTax - discount).toFixed(2);
  },


  /**
   * Returns the currency formatted according to the locale, currency and number given.
   * @param locale
   * @param currency
   * @param number
   * @returns {string}
   */
  formatCurrency(locale, currency, number) {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      currencyDisplay: 'code',
    }).format(number)
      .replace(currency, '')
      .trim();
  },


  /**
   * Remove any special char of a given string.
   * @param str
   * @returns {string}
   */
  removeSpecialChars(str) {
    return String(str).replace(/[!()\/@$%^&*.;\s]/g, "");
  },


  /**
   * Select and element from the DOM with given class or ID.
   * The "allEl" flag returns all the elements with the given class.
   * @param el
   * @param allEl
   * @returns {*[]|*}
   */
  elementSelect(el, allEl = false) {
    el = el.trim();
    if (allEl) {
      return [...document.querySelectorAll(el)];
    } else {
      return document.querySelector(el);
    }
  },

  /**
   * Searches for 'value' property in a given object recursively, checking if its array or not.
   * @param obj
   * @returns {undefined|*}
   */
  retrieveValueProperty(obj) {
    if (obj && obj.value !== undefined) {
      return [obj.value];
    } else if (Array.isArray(obj)) {
      let result = [];
      for (let i = 0; i < obj.length; i++) {
        let val = this.retrieveValueProperty(obj[i]);
        if (val.length > 0) {
          result = result.concat(val);
        }
      }
      return result;
    }
    return obj;
  },

  transformDate(date) {
    const d = new Date(date);
    const day = d.getDay();
    const month = d.getMonth();
  },

  setupPropertyObject(currentCat, properties) {
    const obj = {};
    const categories = {};
    const categoryKeys = [];

    for (const category of currentCat) {
      const key = JSON.stringify(category);
      categories[key] = true;
      categoryKeys.push(key);
    }

    for (const property of properties) {
      const {name, value} = property.category;
      const categoryKey = JSON.stringify({name, value});

      if (!categories[categoryKey]) {
        currentCat.push({name, value});
        categories[categoryKey] = true;
        categoryKeys.push(categoryKey);
      }

      const subCategories = obj[name]?.subCategories ?? {
        Destaques: {
          subCategoryValue: "spotlight",
          properties: [],
        },
        Venda: {
          subCategoryValue: null,
          properties: [],
        },
        Aluguel: {
          subCategoryValue: null,
          properties: [],
        },
      };

      const bmName = property.business_model.name;
      if (property.spotlight === 1) {
        subCategories.Destaques.properties.push(property);
      }

      if (bmName === "Venda" || bmName === "Aluguel") {
        const subCatName = bmName === "Venda" ? "Venda" : "Aluguel";
        subCategories[subCatName].subCategoryValue = property.business_model.value;
        subCategories[subCatName].properties.push(property);
      }

      obj[name] = {
        subCategories,
        categoryValue: value,
      };
    }

    const currentVariables = store.getters.variables;
    store.commit('setVariables', {...currentVariables, 'categories': currentCat});
    return obj;
  }
};

export default utils;
