export default {
  "datatable": {
    "title": {
      "Lista de contatos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of contacts"])},
      "Lista de leads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of leads"])},
      "Lista de prospects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of prospects"])},
      "Lista de clientes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of clients"])},
      "Lista de pedidos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of orders"])},
      "Lista de despesas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of expenses"])},
      "Lista de produtos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of products"])},
      "Lista de serviços": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of services"])},
      "Lista de ocorrências": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of incidents"])},
      "Lista de cargos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of roles"])},
      "Lista de empreendimentos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of enterprises"])}
    },
    "button": {
      "Novo contato": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New contact"])},
      "Novo prospect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New prospect"])},
      "Novo lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New lead"])},
      "Novo cliente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New client"])},
      "Novo produto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New product"])},
      "Novo serviço": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New service"])},
      "Novo imóvel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New real estate"])},
      "Novo empreendimento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New enterprise"])},
      "Nova oportunidade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New opportunity"])},
      "Novo pedido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New order"])},
      "Novo contrato": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New contract"])},
      "Nova despesa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New expense"])},
      "Nova campanha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New campaign"])},
      "Novo conteúdo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New content"])},
      "Nova ocorrência": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New incident"])},
      "Novo FAQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New FAQ"])},
      "Nova Pesquisa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New search"])},
      "Nova Meta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New goals"])},
      "Novo cargo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New role"])},
      "Novo anexo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New attachment"])}
    }
  },
  "header": {
    "title": {
      "Contatos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contatos"])}
    }
  },
  "switchPermissionsComponent": {
    "appointments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agendamentos"])},
    "attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anexos"])},
    "build": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construir"])},
    "business-analytics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise de Negócios"])},
    "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendário"])},
    "campaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campanha"])},
    "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clientes"])},
    "cnaes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CNAEs"])},
    "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contatos"])},
    "content-management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerenciamento de Conteúdo"])},
    "contracts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contratos"])},
    "custom-model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelo Personalizado"])},
    "custom-relationship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relacionamento Personalizado"])},
    "data-library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biblioteca de Dados"])},
    "delegations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delegações"])},
    "digital-sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendas Digitais"])},
    "email-templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelos de E-mail"])},
    "expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Despesas"])},
    "external-site-config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuração de Site Externo"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perguntas Frequentes"])},
    "forecast-analytics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise de Previsão"])},
    "grant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concessão"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Página Inicial"])},
    "import-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importar Dados"])},
    "incidents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incidentes"])},
    "index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Índice"])},
    "integration-authentications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticações de Integração"])},
    "leads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leads"])},
    "opportunities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oportunidades"])},
    "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedidos"])},
    "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissões"])},
    "personal-analytics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise Pessoal"])},
    "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produtos"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfil"])},
    "prospects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prospectos"])},
    "real-estate-development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desenvolvimento Imobiliário"])},
    "real-estate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imóveis"])},
    "relationship-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço de Relacionamento"])},
    "relationship-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de Relacionamento"])},
    "relationships": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relacionamentos"])},
    "reminders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lembretes"])},
    "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funções"])},
    "sales-funnels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funis de Vendas"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviços"])},
    "settings-incidents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurações de Incidentes"])},
    "settings-smtp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurações de SMTP"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurações"])},
    "social-media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mídias Sociais"])},
    "storage-disks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discos de Armazenamento"])},
    "support-tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickets de Suporte"])},
    "sync": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sincronização"])},
    "system-editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editor de Sistema"])},
    "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarefas"])},
    "taxonomy-terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termos de Taxonomia"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuários"])},
    "workflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fluxo de Trabalho"])},
    "workflows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fluxos de Trabalho"])}
  },
  "AsideFilterComponent": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Título"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Início"])},
    "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fim"])},
    "start_hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horário de início"])},
    "end_hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horário de encerramento"])},
    "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data inicial"])},
    "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data final"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrição"])},
    "event_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de evento"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privado"])},
    "attendants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participantes"])},
    "recurrence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recorrência"])}
  },
  "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
  "trade_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Razão Social"])},
  "matriz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matriz"])},
  "situation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situação"])},
  "situation_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Razão da Situação"])},
  "legal_nature_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de Natureza Legal"])},
  "activity_start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de início"])},
  "cnae": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cnae"])},
  "cnpj": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CNPJ"])},
  "cpf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CPF"])},
  "rg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RG"])},
  "identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identidade"])},
  "ssn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSN"])},
  "ein": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EIN"])},
  "rne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RNE"])},
  "document_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de documento"])},
  "document_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número do documento"])},
  "full_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço Completo"])},
  "person_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de pessoa"])},
  "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nacionalidade"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefone"])},
  "cell_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celular"])},
  "site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website"])},
  "company_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porte da Empresa"])},
  "capital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capital Social"])},
  "employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empregados"])},
  "company_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID da empresa"])},
  "user_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuário que registrou"])},
  "birth_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de nascimento"])},
  "contact_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome de contato"])},
  "city_registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscrição municipal"])},
  "state_registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscrição Estadual"])},
  "relationship_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de relacionamento"])},
  "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])},
  "allow_marketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permite marketing"])},
  "allow_interactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permite interações"])},
  "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliação"])},
  "secondary_cnaes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cnae secundário"])},
  "custom_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campos customizados"])},
  "address_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número (endereço)"])},
  "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logradouro"])},
  "neighborhood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bairro"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cidade"])},
  "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrição"])},
  "machine_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome (máquina)"])},
  "deactivated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desativado em"])},
  "email_verified_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail verificado em"])},
  "avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avatar"])},
  "function": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Função"])},
  "default_route": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rota padrão"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço"])},
  "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargo"])},
  "working_hour_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Início de expediente"])},
  "working_hour_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fim de expediente"])},
  "lunch_hour_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Início de pausa"])},
  "lunch_hour_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fim de pausa"])},
  "working_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dias de trabalho"])},
  "work_in_holidays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trabalha em feriados"])},
  "block_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Razão de bloqueio"])},
  "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criado em"])},
  "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizado em"])},
  "Lista de contratos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de contratos"])},
  "Lista de contatos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de contatos"])},
  "List of products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de produtos"])},
  "List of relationships": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de relacionamentos"])},
  "List of orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de pedidos"])},
  "List of expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de despesas"])},
  "leads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leads"])},
  "CustomRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registros customizados"])},
  "Roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargos"])},
  "User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuários"])},
  "UserFavorites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favoritos"])},
  "File": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arquivos"])},
  "ImportData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importador de dados"])},
  "SelectOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seletor de opções"])},
  "Products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produtos"])},
  "RecordTimeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linha do tempo"])},
  "Charts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gráficos"])},
  "Calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendário"])},
  "NotificationsConfig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuração de notificações"])},
  "UserNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificações do usuário"])},
  "Relationships": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relacionamentos"])},
  "ImageSelector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seletor de imagens"])},
  "Integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrações"])},
  "RealEstateDevelopment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorporadoras"])},
  "DataLibrary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biblioteca de dados"])},
  "WorkflowEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evento do workflow"])},
  "Attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anexos"])},
  "EmailTemplates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelos de e-mail"])},
  "Orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedidos"])},
  "SMTP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMTP"])},
  "RelationshipAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço de relacionamentos"])},
  "StorageDisk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discos de armazenamentos"])},
  "Expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Despesas"])},
  "Incidents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocorrências"])},
  "Faqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faqs"])},
  "SupportTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiquetes de suporte"])},
  "SettingIncidents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurações de ocorrências"])},
  "StoredFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtros Salvos"])},
  "ProductImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imagens de produtos"])},
  "Interactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interações"])},
  "Reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reações (mídia social)"])},
  "Polls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionários (mídia social)"])},
  "PollVotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votos dos questionários (mídia social)"])},
  "Opportunities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oportunidades"])},
  "SalesFunnels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funís de venda"])},
  "FunnelStages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estágios dos funís"])},
  "SocialMediaPosts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicações"])},
  "UserSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurações do usuário"])},
  "SettingsIncidents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurações do usuário"])},
  "deleted_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deletado em"])},
  "Lista de relacionamentos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de relacionamentos"])},
  "Lista de usuários": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de usuários"])},
  "Lista de imóveis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de imóveis"])},
  "Lista de pedidos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de pedidos"])},
  "Lista de clientes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de clientes"])},
  "Lista de leads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de leads"])},
  "Lista de prospects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de prospects"])},
  "Lista de despesas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de despesas"])},
  "Lista de empreendimentos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de empreendimentos"])},
  "Lista de modelos de e-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de modelos de e-mail"])},
  "Lista de anexos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de anexos"])},
  "Lista de produtos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de produtos"])},
  "Lista de cargos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de cargos"])},
  "Lista de serviços": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de serviços"])},
  "Serviços": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviços"])},
  "Novo Serviço": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novo Serviço"])},
  "attachment_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria de arquivo"])},
  "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Observações"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome de usuário"])},
  "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria"])},
  "relationship_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID do relacionamento"])},
  "origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origem"])},
  "origin_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de origem"])},
  "selling_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modalidade de venda"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
  "sales_funnel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funil de venda"])},
  "stage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estágio"])},
  "running_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status do cadastro"])},
  "output": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saída"])},
  "output_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivo de saída"])},
  "probability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probabilidade de conversão"])},
  "initial_closing_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data inicial de fechamento"])},
  "expected_closing_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data esperada de fechamento"])},
  "closing_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de fechamento"])},
  "expected_delivery_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de entrega esperada"])},
  "delivery_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de entrega"])},
  "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moeda de negociação"])},
  "initial_expected_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receita esperada"])},
  "expected_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receita calculada"])},
  "signed_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor assinado"])},
  "conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condições de conversão"])},
  "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Método de pagamento"])},
  "expected_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantidade esperada"])},
  "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantidade definitiva"])},
  "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criado por"])},
  "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizado por"])},
  "deleted_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deletado por"])},
  "original_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID original"])},
  "opportunity_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Items da oportunidade"])},
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativo"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Título"])},
  "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Início"])},
  "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fim"])},
  "start_hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horário de início"])},
  "end_hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horário de encerramento"])},
  "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data inicial"])},
  "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data final"])},
  "event_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de evento"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privado"])},
  "attendants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participantes"])},
  "recurrence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recorrência"])},
  "sale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venda"])},
  "rent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aluguel"])}
}