export default {
  "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
  "trade_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre Comercial"])},
  "matriz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matriz"])},
  "situation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situación"])},
  "situation_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivo de Situación"])},
  "legal_nature_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de Naturaleza Legal"])},
  "activity_start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de Inicio de Actividad"])},
  "cnae": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cnae"])},
  "cnpj": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CNPJ"])},
  "cpf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CPF"])},
  "rg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RG"])},
  "identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identidad"])},
  "ssn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSN"])},
  "ein": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EIN"])},
  "rne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RNE"])},
  "document_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de Documento"])},
  "document_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de Documento"])},
  "full_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección Completa"])},
  "person_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de Persona"])},
  "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nacionalidad"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono"])},
  "cell_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono Celular"])},
  "site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitio Web"])},
  "company_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamaño de la Empresa"])},
  "capital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capital Social"])},
  "employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empleados"])},
  "company_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de la Empresa"])},
  "user_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de Usuario"])},
  "birth_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de Nacimiento"])},
  "contact_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de Contacto"])},
  "city_registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro Municipal"])},
  "state_registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro Estatal"])},
  "relationship_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de Relación"])},
  "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiquetas"])},
  "allow_marketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitir Marketing"])},
  "allow_interactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitir Interacciones"])},
  "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valoración"])},
  "secondary_cnaes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cnae Secundario"])},
  "custom_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campos Personalizados"])},
  "address_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número (Dirección)"])},
  "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calle"])},
  "neighborhood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barrio"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciudad"])},
  "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
  "machine_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre (Máquina)"])},
  "deactivated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desactivado en"])},
  "email_verified_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail Verificado en"])},
  "avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avatar"])},
  "function": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Función"])},
  "default_route": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruta Predeterminada"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
  "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargo"])},
  "working_hour_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hora de Inicio de Jornada"])},
  "working_hour_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hora de Fin de Jornada"])},
  "lunch_hour_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hora de Inicio de Pausa"])},
  "lunch_hour_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hora de Fin de Pausa"])},
  "working_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Días Laborales"])},
  "work_in_holidays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trabaja en Feriados"])},
  "block_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivo de Bloqueo"])},
  "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creado en"])},
  "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizado en"])},
  "List of orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de pedidos"])},
  "Lista de pedidos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de pedidos"])},
  "Lista de modelos de e-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de modelos de correo electrónico"])},
  "Lista de empreendimentos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de emprendimientos"])},
  "Empreendimentos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emprendimientos"])},
  "Novo Empreendimento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuevo Emprendimiento"])},
  "Lista de serviços": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de servicios"])},
  "Serviços": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicios"])},
  "Novo Serviço": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuevo Servicio"])}
}